/* Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
}

/* Custom Scroll Bar */
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    background-color: #FFFFFF;
}
::-webkit-scrollbar-thumb {
    background-color: #1A8EFD;
    border-radius: 4rem;
}

 .loading-spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white; 
    z-index: 9999;  
  }
  