.bannerimg img{
  width:100%;
}
.OurServicecards .col-md-3{
  display: flex;
  justify-content: center;
}
.OurServicecards .about-title {
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 30px;
    color:#2f80be;
}
.OurServicecards .about-description {
    color: #000000;
    font-size: 17px;
    margin: 40px 0px;
}
.OurServicecards .about-title > span {
    position: relative;
}
.OurServicecards .about-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: #2f80be;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}

.OurServicecards button{
        font-weight: bold;
    box-shadow: 0px 0px 4px white;
    border: none;
    padding: 2px 10px;
    border-radius: 4px;
}

.OurServicecards h3 {
    text-align: center;
margin: 13px;
    color: #ffffff;
    font-size: 17px;
    font-weight: 500;
}

.OurServicecards p{
    color: white;
    font-size: 16px;
    text-align: center;

}

.OurServicecards  .go-corner {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 32px;
  height: 32px;
  overflow: hidden;
  top: 0;
  right: 0;
  background-color: #000000;
  border-radius: 0 4px 0 32px;
}

.OurServicecards  .go-arrow {
  margin-top: -4px;
  margin-right: -4px;
  color: white;
  font-family: courier, sans;
}

.OurServicecards  .card1 {
  display: block;
  position: relative;
  max-width: 262px;
  background-color: #676767;
  border-radius: 4px;
  padding: 32px 24px;
  margin: 12px;
  text-decoration: none;
  z-index: 0;
  overflow: hidden;

  &:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -16px;
    right: -16px;
    background: #000000;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    transform: scale(1);
    transform-origin: 50% 50%;
    transition: transform 0.25s ease-out;
  }

  &:hover:before {
    transform: scale(21);
  }
}

.OurServicecards  .card1:hover {
  p {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
  h3 {
    transition: all 0.3s ease-out;
    color: #ffffff;
  }
}
