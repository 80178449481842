
#corevalue .header-design {
     background-color: #1b56b0;
    /* background-image: linear-gradient(326deg, #005961 0%, #005961 74%); */
    height: 100px;
    text-align: center;
}

#corevalue header .footer-wave {
     height: 125px;
    left: 0;
    z-index: 1;
    bottom: -67px;
    background: url('../../Assets/Banners/hero-wave.png') repeat-x;
    animation: wave 10s cubic-bezier(0.44, 0.66, 0.67, 0.37) infinite;
}

@keyframes wave {
  0% {
    background-position: 0;
  }
  100% {
    background-position: 1440px;
  }
}
