*{
    font-family: 'Rubik', sans-serif;
}

.hero-section video{
    width: 100%;
} 

/* .home-section .hero-section .row{
    background: #e2f1fe;
} */
.hero-section { 
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.banner-back-color{
        z-index: 99;
    position: relative;
    background: linear-gradient(to right, #ECF2FF, #2f80be);
}

/* @media screen and (max-width: 600px) {
  .banner-back-color .section-container {
     background: linear-gradient(to right, #ECF2FF, #2f80be);
  }
}

@media screen and (min-width: 600px) {
  .banner-back-color .section-container {
      background: url('../../Assets/Banners/homebanner.png') !important;
     background-size: 50% !important;
    background-repeat: no-repeat !important;
    background-position: right !important;
  }
} */

.overlay{
  position:relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(0, 0, 0, 0.205);
  }
/* Text section */
.text-section {  
    padding:47px;
}
.text-headline {
    margin-bottom: 12px;
    color: black;
    font-family: 'Rubik', sans-serif;
    font-size: 22px;
    font-weight: bold;
    
}
.text-title { 
        color: #000000;
    font-family: 'Rubik';
    font-size: 28px;
    /* font-size: 44px; */
    font-weight: bold; 
}
.text-descritpion { 
    margin: 10px 0;
    color: #000000;
    font-family: 'Rubik', sans-serif;
    font-size: 18px;
    
    line-height: 1.6rem;
}
.text-btn {
    margin: 4px;
   padding: 5px 15px;
    color: white;
    border: 1px solid transparent;
    border-radius: 7px;
    outline: transparent;
    background-color: #d5fcf1;
    font-size: 15px;
    cursor: pointer;
    transition: all .4s ease;
}
.text-appointment-btn {
    margin: 4px;
   padding: 7px 20px;
    color: white;
    border: 1px solid transparent;
    border-radius: 7px;
    outline: transparent;
    background-color: #18b187;
    font-size: 15px;
    cursor: pointer;
    transition: all .4s ease;
}
.text-appointment-btn a{
    color: white;
    text-decoration: none;
}
.text-appointment-btn a:hover{
    color: #01059a;
}
.text-appointment-btn i{
   margin-left: 10px;
} 

.text-appointment-btn:hover {
    color: #2f80be;
    background-color: transparent;
    border: 1px solid #000000;
}
.text-stats {
    margin-top: 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 48px;
}
.text-stats-container {
    text-align: center;
}
.text-stats-container > p {
       color:#0a4938;
    font-size: 16px;
    font-weight: bold;
    
}
.text-stats-container p:first-child {
      margin-bottom: 2px;
    color: #0a4938;
    font-size: 30px;
    font-weight: bold;
}
/* Image section */
.hero-image-section {
    width: 40%;
    max-width: 100%;
    text-align: center;
}
.hero-image1 {
    width: 100%;
    height: auto;
}
/* Scroll Btn */
.scroll-up {
    width: 45px;
    height: 45px;
    display: none;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: white;
    border: 3px solid white;
    border-radius: 50%;
    background-color: #2f80be;
    position: fixed;
    bottom: 50px;
    right: 50px;
    font-size: 24px;
    z-index: 20;
    cursor: pointer;
}  
.show-scroll {
  display: flex;
}
/* Responsive */
@media screen and (max-width: 900px) {
    .hero-image-section {
        display: none;
    }
    .text-section {
        width: 100%;
        padding: 20px;
    }
    .text-title, .text-descritpion {
        width: 100%;
    }
}
@media screen and (max-width: 600px) {
    .text-headline {
        font-size: 20px;
    }
    .text-title {
        font-size: 28px;
    }
    .text-descritpion {
        font-size: 16px;
    }
    .text-appointment-btn {
        font-size: 16px;
    }
    .text-stats {
        gap: 18px;
    }
    .text-stats-container > p {
        font-size: 16px;
    }
    .text-stats-container p:first-child {
        font-size: 22px;
    }
    .scroll-up {
        bottom: 32px;
        right: 32px;
    }
}
