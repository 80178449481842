@media screen and (min-width: 680px) {
  .navbar-title {
    display: none;
  }
  .labinmobile {
    display: none;
  }
}
/* html{
  overflow-x: hidden !important;
} */
@media screen and (max-width: 680px) {
  /* .labinmobile .labbtn button {
    color: white;
    padding: 0px 20px; 
    background: #14918e;
    font-weight: 400; 
    margin-top: 10px;
    display: flex;
  } */
  .labinmobile .labbtn {
    color: white;
    padding: 0px 20px; 
    background: #14918e;
    font-weight: 400;
    height: 100%; 
    display: flex;
    border: 2px solid white;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .labinmobile .right .labbtn{
     background: #141c91 !important;
  }
  .labinmobile .labbtn a{
    color: white;
    display: block;
    text-align: center;
    text-decoration: none;
    /* font-weight: bold; */
  }
  .labinmobile {
    display: flex;
    width: 100%;
    background-color: #018f8d;
  } 
  .labinmobile .left {
    width: 50%;
  }
  .labinmobile .right {
    width: 50%;
  }
}

.neologo {
  max-width: 100px;
  height: auto;
}
.navbar-section .labbtn {
  color: white;
  padding: 0px 20px;
  border: 1px solid #14918e;
  background: #14918e;
  font-weight: 400;
  margin-top: 10px;
}

@media screen and (max-width:600px){
  .navbar-section {
  z-index: 9999;
  top: 0;
  position: sticky;
  box-shadow: 0px 2px 3px -2px #00000080 ;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 20px;
  background-color: #ffffff;
  /* font-weight: 500; */
  font-family:  Helvetica;
}
}
@media screen and (min-width:600px){
    .navbar-section {
    z-index: 9999;
    top: 0;
    position: sticky;
    box-shadow: 0px 2px 3px -2px #00000080 ;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 5px;
    background-color: #ffffff;
    /* font-weight: 500; */
    font-family:  Helvetica;
  }
}
 
.navbar-title a {
  color: #018f8d;
  letter-spacing: 0.6px;
  text-decoration: none;
}
.navbar-title {
  font-family: "Poppins", sans-serif;
}
.navbar-sign {
  color: #54de54;
  font-family: Cambria, sans-serif;
  font-size: 40px;
  font-weight: bold;
}
.navbar-items {
  margin: 0px;
  list-style-type: none;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 22px;
  font-family: "Rubik", sans-serif;
}
.navbar-items > li {
  list-style-type: none;
}
.navbar-links {
  text-decoration: none;
  color: black;
  font-size: 18px;
  letter-spacing: 0.8px;
}
.navbar-links:hover {
  color: #0cc2ea;
}
.navbar-btn {
  padding: 14px 20px;
  color: white;
  border: 1px solid transparent;
  border-radius: 28px;
  outline: transparent;
  background-color: #018f8d;
  font-size: 18px;
  font-family: "Rubik", sans-serif;
  letter-spacing: 0.8px;
  cursor: pointer;
  transition: all 0.4s ease;
}
.navbar-btn:hover {
  color: #018f8d;
  background-color: white;
  border: 1px solid #018f8d;
}
/* Hamburger Icon */
.mobile-nav {
  display: none;
}
.hamb-icon {
  width: 26px;
  height: 26px;
  cursor: pointer;
}
.hamb-icon:hover {
  color: #0cc2ea;
}
/* Mobile Navbar */
.mobile-navbar {
  padding: 40px;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: -100%;
  background-color: white;
  z-index: 20;
  justify-content: center;
  align-items: center;
  transition: left 0.5s ease-in-out;
}
.mobile-navbar-close {
  position: absolute;
  top: 28px;
  right: 28px;
}
.mobile-navbar-close .hamb-icon:hover {
  color: rgb(255, 22, 22);
}
.open-nav {
  left: 0;
}
.mobile-navbar-links {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  gap: 24px;
  text-align: center;
}
.mobile-navbar-links li a {
  text-decoration: none;
  color: black;
  font-family: "Poppins", sans-serif;
  font-weight: bold;
  letter-spacing: 0.8px;
  transition: color 0.3s ease-in-out;
}
.mobile-navbar-links li a:hover {
  color: #0cc2ea;
}
/* Responsive */
@media screen and (max-width: 900px) {
  .navbar-btn,
  .navbar-items {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
}
