.NeoBlogDetails h2 {
  font-size: 20px;
}
.NeoBlogDetails h1 {
  position: relative;
  font-size: 25px;
  font-weight: 800;
  padding: 30px 0px;
}
.NeoBlogDetails a {
  text-decoration: none;
  font-size: 16px;
}
.NeoBlogDetails h1::after {
  content: "";
  position: absolute;
  width: 50%;
  height: 4px;
  background-color: #005961;
  border-radius: 8px;
  bottom: 0;
  left: 0;
}

#Blogbanner img {
  width: 100%;
  height: auto;
}

.NeoBlog {
  margin-bottom: 100px;
}
.NeoBlog .dt-title-content {
  margin: 0 0 30px 0;
}
.NeoBlog .dt-title {
  font-weight: bold;
}
.NeoBlog .dt-title > span {
  position: relative;
}
.NeoBlog .dt-title span::before {
  content: "";
  position: absolute;
  width: 64%;
  height: 4px;
  background-color: #9d2553;
  border-radius: 8px;
  left: 0;
}
.NeoBlog .dt-description {
  font-size: 20px;
  margin-top: 30px;
  color: rgb(0, 0, 0);
}

.NeoBlog .container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 16px;
}

.NeoBlog .card-container {
  background-color: #fff;
  margin-top: 15px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px #95c3c2;
  overflow: hidden;
}

.NeoBlog .card-body h2 {
  font-weight: bold;
  margin-top: 17px;
  font-size: 14px;
  color: #010101;
  height: 50px;
  overflow: hidden;
}

.NeoBlog .Readmore a {
  font-size: 14px;
  text-decoration: none;
  color: #005494;
}

.NeoBlog .card-image img {
  width: 100%;
  border-radius: 8px 8px 0 0;
  background-size: cover;
}

.NeoBlog .card-body {
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 16px;
  min-height: 200px;
}

.NeoBlog .card-badge {
  text-transform: uppercase;
  background-color: #fff;
  color: #fff;
  padding: 2px 8px;
  border-radius: 70px;
  margin: 0;
  font-size: 12px;
}

.NeoBlog .card-badge-blue {
  background-color: #000000;
}

.NeoBlog .card-badge-purple {
  background-color: #3d1d94;
}

.NeoBlog .card-badge-pink {
  background-color: #c62bcb;
}

.NeoBlog .card-body h1 {
  font-size: 16px;
  margin: 8px 0;
}

.NeoBlog .card-body p {
  font-size: 14px;
  margin: 8px 0 16px 0;
}

.NeoBlog .card-author {
  display: flex;
  align-items: center;
}

.NeoBlog .card-author p {
  margin: 0 16px;
  font-size: 12px;
}

.NeoBlog .card-author p:last-child {
  color: #888;
}

.NeoBlog .card-author img {
  border-radius: 50%;
  height: 48px;
  width: 48px;
  margin-top: auto;
}

@media screen and (max-width: 1000px) {
  .NeoBlog .container {
    grid-template-columns: 1fr;
  }
}

.card-author {
  display: flex;
}

.author-info {
  display: flex;
  justify-content: flex-end;
}

.NeoBlogDetails .blogtitle {
  color: black;
  font-family: serif;
}
.NeoBlogDetails .description {
  color: black;
  font-family: serif;
}

.NeoBlogDetails .blogbtn {
  color: black;
  /* border-bottom: 7px solid black; */
  border: none;
  background-color: transparent;
  padding: 0px 3px;
}

.blog_side_form .input {
  border: none;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}

.blog_side_form .input:focus {
  box-shadow: none;
  outline: none;
  border-bottom: 2px solid #1325e8;
}

.blog_side_form button {
  background: linear-gradient(to top right, #04656c -5%, #18b187 100%);
  border: none;
  color: #fff;
  padding: 10px 5px;
  width: 100%;
  margin-top: 19px;
  border-radius: 9px;
  cursor: pointer;
  font-size: 17px;
  letter-spacing: 2px;
}
