#about{
    margin-top: 60px;
}
.aboutbanner .banner-img{
    width: 100%;
    height: auto;

}
#about h4{
        font-weight: 700;
    margin-top: 20px;
    color: #046f54;
}
#about .about-section {
    padding: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    background: linear-gradient(to right, #ECF2FF, #FBFCFF);
}

#about .about-title {
    font-family: 'Poppins', sans-serif;
    font-size: 22px;
    font-weight: bold;
}
#about .about-title > span {
    position: relative;
}
#about .about-title span::before {
    content: '';
    position: absolute;
    width: 75%;
    height: 4px;
    background-color: #0b4e8d;
    border-radius: 8px;
    left: 0;
    bottom: -12px;
}
#about .about-description {
    color: #000000;
    font-size: 17px;
    margin: 40px 0px;
}